/** @jsxImportSource @emotion/react */
import { DialogHTMLAttributes } from 'react';
import { Store } from '../../types';

import styles from './styles';
import { Column } from './Stores';

export interface IStoreProps extends DialogHTMLAttributes<HTMLDivElement> {
  store: Store;
  columns: Column[];
}

const WARNING_EXTRA_TIME = 5; // minutes
const StoreRow: React.FunctionComponent<IStoreProps> = ({ store, columns }) => {
  const now = new Date();
  const sDate = new Date(store.lastSync);
  const diff = Math.abs(now.getTime() - sDate.getTime()) / 1000;
  const sleepTimeSeconds = store.sleepTimeSeconds;
  let error = diff > sleepTimeSeconds + WARNING_EXTRA_TIME * 60;
  let warning = false;
  let bigError = diff > 60 * 60 * 24;
  if (store.lastTicket?.timeEndDate) {
    const lastTicketDate = new Date(store.lastTicket.timeEndDate);
    warning = Math.abs(now.getTime() - lastTicketDate.getTime()) / 1000 > 60 * 60;
    error = error || Math.abs(now.getTime() - lastTicketDate.getTime()) / 1000 > 60 * 60 * 24;
  }

  return (
    <div
      css={[
        styles.storeRow,
        warning && styles.warning,
        error && styles.error,
        bigError && styles.bigError,
      ]}
    >
      {columns.map((column) => {
        const key = column.key;
        if (column.render) {
          return (
            <div key={key} css={styles.cell}>
              {column.render(store)}
            </div>
          );
        }
        return (
          <div key={key} css={styles.cell}>
            {store[key]}
          </div>
        );
      })}
    </div>
  );
};

export default StoreRow;
