import React, { Suspense, DialogHTMLAttributes } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
// import { drawerWidth } from './components/sideBar/Sidebar';
import PublicRoute from './authtenticateRoutes/PublicRoute';
import PrivateRoute from './authtenticateRoutes/PrivateRoute';
import Login from './authtenticateRoutes/Login';
import Stores from './Stores';
import Store from './Store';
import FacturasPage from '../pages/Facturas/Facturas';
import ConsultasPage from '../pages/Consultas/Consultas';
import Loading from '../components/UI/Loading/Loading';
import AdminConsultasPage from '../pages/AdminConsultas/AdminConsultasFacturas';
import AdminConsultasNotasPage from '../pages/AdminConsultas/AdminConsultasNotas';

const Container = styled.div`
  margin: 70px 0 0 0;
`;

export interface IRoutesProps extends DialogHTMLAttributes<HTMLDialogElement> {}

const Router: React.FunctionComponent = () => {
  return (
    <Suspense fallback={<Loading />}>
      <BrowserRouter>
        {/* FKA UnauthorizedRoute */}
        <Routes>
          <Route element={<PublicRoute />}>
            <Route path="/" element={<Navigate to="/facturas" />} />
            <Route path="/facturas" element={<FacturasPage />} />
            <Route path="/consultas" element={<ConsultasPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/facturas" />} />
          </Route>
          {/* FKA Authonticated Route */}
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<Navigate to="/stores" />} />
            <Route path="/stores" element={<Stores />} />
            <Route path="/stores/:store" element={<Store />} />
            <Route path="/admin/consultas/Facturas" element={<AdminConsultasPage />} />
            <Route path="/admin/consultas/Notas" element={<AdminConsultasNotasPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default Router;
