import React from 'react';

const Success = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <circle cx="9" cy="9" r="9" fill="#68CA87" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9075 4C11.2693 5.31692 9.34157 7.03807 8.06427 8.43167C7.63771 8.89754 7.28508 9.32576 7.00118 9.69366L4.72105 7.36674L3 8.89665L7.39948 13.3857L8.18409 12.0408C8.19021 12.0293 8.75636 11.0754 9.89171 9.84701C11.0278 8.61707 12.7305 7.11699 15 5.9857L13.9075 4Z"
      fill="white"
    />
  </svg>
);

export default Success;
