/** @jsxImportSource @emotion/react */
import React, { useRef } from 'react';
import { DialogHTMLAttributes } from 'react';
import styles from './styles';
import LoadingSpinner from './LoadingSpinner';
export interface ILoadingProps extends DialogHTMLAttributes<HTMLDialogElement> {
  infiniteScroll?: boolean;
  card?: boolean;
}

const Loading: React.FunctionComponent<ILoadingProps> = ({ infiniteScroll, card }) => {
  return (
    <div
      css={[
        styles.loadingContainer,
        infiniteScroll && styles.loadingInfinite,
        card && styles.loadingCard,
      ]}
      data-cy="loading"
    >
      Loading...
      <LoadingSpinner />
    </div>
  );
};

export default Loading;
