import React from 'react';

export interface InputProps {
  type?: string;
  label: string;
  value: any;
  disabled?: boolean;
  placeholder?: string;
  onChange?: (value: any) => void;
}

const defaultProps: Partial<InputProps> = {
  type: 'text',
  disabled: false,
  placeholder: '',
};

const Input: React.FC<InputProps> = (props) => {
  const mergedProps = { ...defaultProps, ...props };
  const { type, label, value, disabled, placeholder, onChange } = mergedProps;
  return (
    <label style={{ display: 'flex', marginBottom: '10px', flexDirection: 'column' }}>
      {label}
      <input
        type={type}
        defaultValue={value}
        disabled={disabled}
        placeholder={placeholder}
        onChange={(e) => {
          if (onChange) {
            onChange(e.target.value);
          }
        }}
        style={{
          display: 'block',
          padding: '8px',
          fontSize: '16px',
          borderRadius: '4px',
          border: '1px solid #ddd',
          maxWidth: '300px',
        }}
      />
    </label>
  );
};

export default Input;
