import { gql } from '@apollo/client';

const storeQuery = gql`
  query storeQuery($name: String, $id: ID) {
    store(name: $name, id: $id) {
      id
      name
      sleepTimeSeconds
      abbreviation
      syncingWithCfdi
      pluginVersion
      lastSync
      lastTicket {
        id
        timeEndDate
        transactNumber
      }
    }
  }
`;

export default storeQuery;
