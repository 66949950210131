import { css } from '@emotion/react';

const styles = {
  storeRow: css`
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    column-gap: 1rem;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
  `,
  storeTable: css`
    display: grid;
    border: 1px solid #ccc;
    margin: 20px 0;
    overflow: auto;
  `,

  columnHeader: css`
    font-weight: bold;
  `,

  cell: css`
    flex: 1;
    padding: 5px;
    text-align: center;
    width: 80px;
    margin: auto;
  `,
  error: css`
    background-color: #f8d7da;
    color: #721c24;
  `,

  bigError: css`
    background-color: #ff3333;
    color: #000;
  `,

  warning: css`
    background-color: #fff3cd;
    color: #856404;
  `,
};

export default styles;
