import gql from 'graphql-tag';

const userFragment = gql`
  fragment UserFragment on User {
    id
    email
    name
    lastName
  }
`;

export default userFragment;
