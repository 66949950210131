/** @jsxImportSource @emotion/react */
import { DialogHTMLAttributes } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useQuery, useMutation } from '@apollo/client';
import { storeQuery } from '../../graphql/private/query';
import storeUpdateMutation from '../../graphql/private/mutation/storeUpdate';
import RHFInput from '../../components/UI/Input/RHFInput';
import Input from '../../components/UI/Input/Input';
import ToggleButton from '../../components/UI/ToggleButton/ToggleButton';
import TicketsTable from './TicketsTable';
import styles from './styles';
import Loading from '../../components/UI/Loading/Loading';

export interface IStoreProps extends DialogHTMLAttributes<HTMLDivElement> {}

interface FormValues {
  abbreviation: string;
  sleepTimeSeconds: number;
  syncingWithCfdi: boolean;
}

const Store: React.FunctionComponent<IStoreProps> = (props) => {
  const { store } = useParams<{ store: string }>();

  const { loading, error, data } = useQuery(storeQuery, {
    variables: { name: store, first: 10, after: null },
  });

  const [updateStore] = useMutation(storeUpdateMutation);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const onSubmit = (_data: FormValues) => {
    updateStore({
      variables: {
        id: data.store.id,
        syncingWithCfdi: _data.syncingWithCfdi,
        sleepTimeSeconds: parseInt(_data.sleepTimeSeconds.toString()),
        abbreviation: _data.abbreviation,
      },
    });
  };

  if (loading) return <Loading />;
  if (error) return <p>Error :(</p>;

  return (
    <div
      style={{
        padding: '20px',
      }}
    >
      <h1>Store: {store}</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="syncingWithCfdi"
          control={control}
          defaultValue={data.store.syncingWithCfdi}
          render={({ field }) => (
            <ToggleButton
              {...field}
              onChange={() => {
                field.onChange(!field.value);
              }}
              active={field.value}
              id="syncingWithCfdi"
              disabled={false}
              label="Syncing with CFDI"
            />
          )}
        />

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '20px',
            marginTop: '20px',
          }}
        >
          <Input
            label="Last Sync"
            value={new Date(data.store.lastSync).toLocaleString()}
            disabled={true}
          />
          <Input
            label="Last Ticket Sync"
            value={
              data.store.lastTicket?.timeEndDate
                ? new Date(data.store.lastTicket.timeEndDate).toLocaleString()
                : '-'
            }
            disabled={true}
          />
          <Input
            label="Last Ticket"
            value={data.store.lastTicket?.transactNumber}
            disabled={true}
          />
          <Input label="Plugin Version" value={data.store.pluginVersion} disabled={true} />
          <RHFInput
            type="text"
            label="Abbreviation"
            defaultValue={data.store.abbreviation}
            errors={errors}
            register={register}
            name="abbreviation"
            rules={{ required: true, maxLength: 5 }}
          />
          <RHFInput
            type="number"
            label="Sleep Time (s)"
            defaultValue={data.store.sleepTimeSeconds}
            errors={errors}
            register={register}
            name="sleepTimeSeconds"
            rules={{ required: true }}
          />
        </div>

        <button type="submit">Submit</button>
      </form>
      <TicketsTable store={data.store} />
    </div>
  );
};

export default Store;
