/** @jsxImportSource @emotion/react */
import { DialogHTMLAttributes, JSXElementConstructor, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styles from './styles';
import axios from 'axios';
import Success from '../../assets/icons/Success';
import { AlertCircle } from 'react-feather';
import { isLoggedIn, setUser } from '../../utils/authentication';
import { useQuery } from '@apollo/client';
import useQueryParams from '../../components/hooks/useQueryParams';
import useMixPanel from '../../utils/hooks/useMixPanel';
export interface ILoginProps extends DialogHTMLAttributes<HTMLDialogElement> {}

interface IFormValidation {
  type: string | null;
  message?: string | null;
}

const getLoginFormMessage = (paramKey: string) => {
  switch (paramKey) {
    case 'setPassword':
      return 'Password updated successfully!';
    case 'sentPasswordReset':
      return 'The reset link was sent to your email!';
    case 'loggedOut':
      return 'Logged out successfully.';
    default:
      return null;
  }
};

const Login: React.FunctionComponent<ILoginProps> = (props) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const loginParamEntries = queryParams.entries();
  const [formValidation, setFormValidation] = useState<IFormValidation>({
    type: null,
    message: null,
  });

  const { trackEvent, identify, peopleSet } = useMixPanel();
  useEffect(() => {
    trackEvent('Login Page Loaded');
  }, []);

  useEffect(() => {
    let param = loginParamEntries.next();
    while (!param.done) {
      const paramKey = param.value[0];
      setFormValidation({
        type: 'success',
        message: getLoginFormMessage(paramKey),
      });

      param = loginParamEntries.next();
    }
  }, [loginParamEntries]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onLoginSugmit = async (data: any) => {
    const { email, password } = data;

    try {
      const headers = {
        Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/login`,
        {
          email,
          password,
        },
        { headers }
      );
      if (response.data.token) {
        await setUser(response.data.token, response.data.user);
        setFormValidation({
          type: 'success',
          message: 'Login successful',
        });
      }
    } catch (error: any) {
      let errorMessage;
      switch (error.response && error.response.status) {
        case 400:
        case 401:
          errorMessage = 'Invalid email or password';
          break;
        case 429:
          errorMessage = 'Too many requests. Try again later';
          break;
        default:
          errorMessage = 'Something went wrong';
          break;
      }
      setFormValidation({
        type: 'error',
        message: errorMessage,
      });
    }
  };

  useEffect(() => {
    if (isLoggedIn()) {
      navigate('../stores');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn()]);

  return (
    <form css={styles.container} onSubmit={handleSubmit(onLoginSugmit)}>
      <h1>Log in</h1>
      {formValidation && formValidation.type && (
        <div css={styles.formValidationContainer}>
          {formValidation.type === 'success' ? (
            <Success />
          ) : (
            <AlertCircle size={20} color="#FF3A51" />
          )}
          <span>{formValidation.message}</span>
        </div>
      )}
      <div css={styles.input}>
        <input
          {...register('email', {
            required: 'Email is required',
            pattern: {
              value: /^\S+@\S+$/i,
              message: 'Invalid email address',
            },
          })}
        />
        {errors.email && <span>{errors.email.message?.toString()}</span>}
      </div>
      <div css={styles.input}>
        <input type="password" {...register('password', { required: 'Password is required' })} />
        {errors.password && <span>{errors.password.message?.toString()}</span>}
      </div>
      <input css={styles.button} type="submit" />
    </form>
  );
};

export default Login;
