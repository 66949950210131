import gql from 'graphql-tag';
/*

      field :id, ID, null: false
      field :transact_number, String, null: false
      field :net_amount, Float, null: false
      field :tax_amount, Float, null: false
      field :total_amount, Float, null: false
      field :payment_type, String, null: false
      field :payment_reference, String, null: true
      field :payment_date, GraphQL::Types::ISO8601DateTime, null: false
      field :time_end_date, GraphQL::Types::ISO8601DateTime, null: true
      field :details, [GraphQL::Types::JSON], null: false
      field :store, Types::StoreType, null: false
      */
const ticketFragment = gql`
  fragment TicketFragment on Ticket {
    id
    transactNumber
    netAmount
    taxAmount
    totalAmount
    paymentType
    paymentReference
    paymentDate
    timeEndDate
    details
    invoiceNumber
    invoiced
    invoiceDate
    email
    serie
    folioCFDI
    store {
      id
      name
      abbreviation
    }
    client {
      id
      name
      rfc
    }
  }
`;

export default ticketFragment;
