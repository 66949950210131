import { css } from '@emotion/react';

const styles = {
  loadingContainer: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
  `,
  loadingInfinite: css`
    width: 100%;
    height: 120px;
  `,
  loadingCard: css`
    width: 100%;
    height: 185px; /* height of earnings summary emtpy state */
  `,
};

export default styles;
