import React from 'react';
import { Outlet } from 'react-router-dom';

const APP_NAME = 'Facturacion DQ Guadalajara';

const PublicRoute: React.FunctionComponent = () => {
  return (
    <div>
      {/* <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: '#d0f0fa',
        }}
      >
        <div style={{ margin: 'auto', textAlign: 'center' }}>{APP_NAME}</div>
      </div> */}
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default PublicRoute;
