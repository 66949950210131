import { gql } from '@apollo/client';

const storesQuery = gql`
  query storesPrivateQuery {
    stores {
      id
      name
      sleepTimeSeconds
      abbreviation
      syncingWithCfdi
      pluginVersion
      lastSync
      lastTicket {
        id
        timeEndDate
        transactNumber
      }
    }
  }
`;

export default storesQuery;
