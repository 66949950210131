/** @jsxImportSource @emotion/react */
import { DialogHTMLAttributes, Fragment, JSXElementConstructor, useEffect, useState } from 'react';
import { Globe, Menu } from 'react-feather';
import dqLogo from '../../../assets/icons/dqLogo.svg';
import { useNavigate, useOutletContext } from 'react-router-dom';
import axios from 'axios';
import { getAuthToken, logoutUser } from '../../../utils/authentication';
import styles from './styles';
import './styles.css';
export interface IHeaderProps extends DialogHTMLAttributes<HTMLDialogElement> {}

const Header: React.FunctionComponent<IHeaderProps> = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const currentUser = useOutletContext();
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const navigate = useNavigate();

  const activePath = window.location.pathname;

  const handleLogout = async () => {
    const response = await axios.post(`${process.env.REACT_APP_API_HOST}/logout`, {
      token: getAuthToken(),
    });
    if (response.status === 200) {
      logoutUser();
      navigate('/login', { state: 'loggedOut' });
    }
  };
  const handleTabChange = (tab: string) => {
    switch (tab) {
      case 'factura':
        navigate('/facturas');
        break;
      case 'consultar_facturas':
        navigate('/admin/consultas/facturas');
        break;
      case 'consulta_notas':
        navigate('/admin/consultas/notas');
        break;
      default:
        break;
    }
  };

  return (
    <header
      className="header"
      style={{
        position: 'sticky',
        top: '0',
        zIndex: 1,
        padding: '4px 8px',
        backgroundColor: '#d0f0fa',
      }}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr auto 1fr',
        }}
      >
        <div style={{ display: 'flex' }}>
          <img src={dqLogo} alt="DQ Logo" style={{ width: '100px' }} onClick={handleLogout} />
          <div className={`menu ${menuOpen ? 'open' : ''}`}>
            <div className="tabs" css={styles.navButtonContainer}>
              <button
                css={[styles.navButton, activePath === '/facturas' && styles.activeNavButton]}
                type="button"
                onClick={() => {
                  handleTabChange('factura');
                }}
              >
                Factura
              </button>
              {currentUser !== null && currentUser !== undefined && (
                <Fragment>
                  <button
                    css={[
                      styles.navButton,
                      activePath === '/admin/consultas/facturas' && styles.activeNavButton,
                    ]}
                    type="button"
                    onClick={() => {
                      handleTabChange('consultar_facturas');
                    }}
                  >
                    Consulta Facturas
                  </button>
                  <button
                    css={[
                      styles.navButton,
                      activePath === '/admin/consultas/notas' && styles.activeNavButton,
                    ]}
                    type="button"
                    onClick={() => {
                      handleTabChange('consulta_notas');
                    }}
                  >
                    Consulta Notas
                  </button>
                </Fragment>
              )}
            </div>
          </div>
        </div>

        <div
          css={styles.title}
          style={{
            margin: 'auto',
            textAlign: 'center',
            fontSize: '24px',
            fontFamily: 'inherit',
            fontWeight: 'bold',
          }}
        >
          Facturacion DQ Guadalajara
        </div>
        {/* <div style={{ color: '#6d7577', display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
          <Globe size={14} style={{ marginBlock: 'auto' }} />
          <div style={{ marginBlock: 'auto' }}>{`Español [MX]`}</div>
        </div> */}
        <button className="menu-toggle" onClick={toggleMenu}>
          <Menu size={24} />
        </button>
      </div>
    </header>
  );
  /*
  return (
    <div
      style={{
        position: 'sticky',
        top: '0',
        zIndex: 1,
        padding: '4px 8px',
        backgroundColor: '#d0f0fa',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <img src={dqLogo} alt="DQ Logo" style={{ width: '100px' }} />
        <div css={styles.navButtonContainer}>
          <button
            css={[styles.navButton, !isConsultas && styles.activeNavButton]}
            type="button"
            onClick={() => {
              handleTabChange('factura');
            }}
          >
            Factura
          </button>
          <button
            css={[styles.navButton, isConsultas && styles.activeNavButton]}
            type="button"
            onClick={() => {
              handleTabChange('consulta');
            }}
          >
            Consulta
          </button>
        </div>
        <div style={{ margin: 'auto', textAlign: 'center' }}>Facturacion DQ Guadalajara</div>
        <div style={{ color: '#6d7577', display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
          <Globe size={14} style={{ marginBlock: 'auto' }} />
          <div style={{ marginBlock: 'auto' }}>{`Español [MX]`}</div>
        </div>
      </div>
    </div>
  );

  */
};

export default Header;
