/* eslint-disable import/prefer-default-export */

/* colors */
export const colors = {
  /* Main */
  lightGrayBlue: '#F6F8FA',
  lilacPurple: '#F2F4FF',
  gray: '#DEE2E6',
  navy: '#3D518C',
  darkNavy: '#273459',
  lightGray: '#F0F2F6',
  alternativeGreen: '#23C49E',
  marigold: '#FFBE0B',
  blackBody: '#1D262C',
  chiliColor: '#FF3A51',
  darkChiliColor: '#D2142A',
  badgeGreen: '#2A856F',
  mobileBackground: '#F8F9FD',

  /* Primary */
  blurple: '#4D61FF',
  blurple1: '#E9EAFD',
  blurple2: '#C7C9FB',
  blurple3: '#9FA7F9',
  blurple4: '#7483F7',
  blurple5: '#4E67F6',
  blurple6: '#1B4AF2',
  blurple7: '#1341E6',
  blurple8: '#0036D9',
  blurple9: '#002ACF',
  blurple10: '#0017B6',

  /* Secondary */
  teal: '#4F7484',
  lightTeal1: '#E8F1F7',
  lightTeal2: '#CCDBE2',
  lightTeal3: '#AFC2CD',
  lightTeal4: '#91A9B7',
  lightTeal5: '#7A96A4',
  teal1: '#638293',
  teal2: '#577482',
  teal3: '#47606D',
  teal4: '#394D57',
  teal5: '#263740',

  /* Complementary */
  yellow: '#FCE96A',
  yellow1: '#FFFCE8',
  yellow2: '#FEF8C7',
  yellow3: '#FEF2A3',
  yellow4: '#FDED82',
  yellow5: '#FCE96A', // same as yellow
  yellow6: '#FAE456',
  yellow7: '#F7D450',
  yellow8: '#F2BC48',
  yellow9: '#EEA63F',
  yellow10: '#E77F32',

  /* Neutral */
  gray1: '#F0F2F6', // same as lightGray
  gray2: '#DADFE6',
  gray3: '#C3CAD5',
  gray4: '#AAB5C3',
  gray5: '#97A3B4',
  gray6: '#8491A6',
  gray7: '#758193',
  gray8: '#626D7B',
  gray9: '#525A66',
  gray10: '#3E444D',

  /* Analogous */
  skyBlue: '#6ABAF9',
  skyBlue1: '#F7FCFF',
  skyBlue2: '#C2E6FD',
  skyBlue3: '#9CD6FB',
  skyBlue4: '#7BC6FA',
  skyBlue5: '#6ABAF9',
  skyBlue6: '#61AEF7',
  skyBlue7: '#5AA0E7',
  skyBlue8: '#518DD2',
  skyBlue9: '#497BBF',
  skyBlue10: '#3A5C9B',

  purple: '#D85FF7',
  purple1: '#F0E8FD',
  purple2: '#D7C7FA',
  purple3: '#BCA1F9',
  purple4: '#9F78F7',
  purple5: '#8758F6',
  purple6: '#6E37F4',
  purple7: '#6131ED',
  purple8: '#5029E5',
  purple9: '#3C22E0',
  purple10: '#131DD1',

  /* Triadic */
  pink: '#D85FF7',
  pink1: '#F9E6FD',
  pink2: '#EEC0FA',
  pink3: '#E393F9',
  pink4: '#D85FF7',
  pink5: '#D03AF6',
  pink6: '#C336EB',
  pink7: '#B233E7',
  pink8: '#9A2EE1',
  pink9: '#8429DB',
  pink10: '#5822D1',

  red: '#EC5B67',
  red1: '#FCEDF1',
  red2: '#F8D0D9',
  red3: '#EBA0A6',
  red4: '#E27A83',
  red5: '#EC5B67',
  red6: '#EC4850',
  red7: '#E5424F',
  red8: '#D33847',
  red9: '#C72F3F',
  red10: '#B92634',
};
