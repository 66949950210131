import { css } from '@emotion/react';

const styles = {
  table: css`
    display: grid;
    border: 1px solid #ccc;
    margin: 20px 0;
    overflow: auto;
  `,
  row: css`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1rem;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
  `,
  cell: css`
    flex: 1;
    padding: 5px;
    text-align: center;
    width: 80px;
    margin: auto;
  `,
  header: css`
    font-weight: bold;
  `,
};

export default styles;
