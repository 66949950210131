import { useLocation } from 'react-router-dom';

/*
 *  Example:
 *          import { useLocation } from "react-router-dom";
 *          const queryParams = use-query-params(useLocation);
 *          const paramValue = queryParams.get('paramKey')
 *
 * Alternative:
 *          import { useSearchParam } from 'react-use';
 *          const email = useSearchParam('email');
 */
const useQueryParams = () => {
  return new URLSearchParams(useLocation().search);
};

export default useQueryParams;
