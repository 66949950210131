import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { currentUserQuery } from '../../graphql/private/query';
import Header from '../../components/UI/Header/Header';
import { isLoggedIn, logoutUser } from '../../utils/authentication';
import useMixPanel from '../../utils/hooks/useMixPanel';

const requestUnauthorized = (error: any) => {
  // TODO: Make a better page for unauthorized
  return !!error.graphQLErrors.find((e: any) => e.message === 'unauthorized');
};
const PrivateRoute: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { loading, data, error } = useQuery(currentUserQuery);
  const { identify, peopleSet } = useMixPanel();
  useEffect(() => {
    if (data) {
      if (data.currentUser === null) {
        logoutUser();
        return navigate('login', { state: 'loggedOut' });
      }
      const user = data.currentUser;
      identify(user.id);
      peopleSet({
        $email: user.email,
        $name: user.name,
      });
    }
  }, [data]);

  if (error && requestUnauthorized(error)) {
    logoutUser();
    navigate('login', { state: 'loggedOut' });
  }

  if (data?.currentUser === null) {
    logoutUser();
    navigate('login', { state: 'loggedOut' });
  }

  if (isLoggedIn()) {
    return (
      <div>
        <Outlet context={data?.currentUser} />
      </div>
    );
  }

  return <Navigate to="/login" state={{ from: location }} replace />;
};

export default PrivateRoute;
