import React from 'react';
import { InMemoryCache, ApolloClient, ApolloProvider, HttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Router from './routes';
import { getAuthToken } from './utils/authentication';
export interface IAppProps {}

const httpLink = new HttpLink({ uri: `${process.env.REACT_APP_API_HOST}/graphql` });

const authLink = setContext(async (_, { headers }) => {
  const token = getAuthToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});
const client = new ApolloClient({
  link: from([authLink, httpLink]),
  cache: new InMemoryCache(),
});

const App: React.FunctionComponent<IAppProps> = (props) => {
  return (
    <ApolloProvider client={client}>
      <Router />
    </ApolloProvider>
  );
};

export default App;
