import React, { useState } from 'react';

import 'react-dates/initialize';

import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';
import moment from 'moment';
import classNames from 'classnames';
import 'react-dates/lib/css/_datepicker.css';
import './style.css';

const DateRangeBieber = ({ startDate, endDate, onDatesChange, uniqueName, theme, disabled }) => {
  const [focusedInput, setFocusedInput] = useState(null);
  const isActive = startDate && endDate;
  return (
    <div
      className={classNames('custom-date-range', `date-range--${theme}-theme`, {
        active: isActive,
        'date-wrapper': !isActive,
      })}
    >
      <DateRangePicker
        isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
        minimumNights={0}
        disabled={disabled}
        startDate={startDate}
        startDateId={`${uniqueName}-start-date`}
        endDate={endDate}
        endDateId={`${uniqueName}-end-date`}
        onDatesChange={onDatesChange}
        focusedInput={focusedInput}
        onFocusChange={setFocusedInput}
        initialVisibleMonth={() => moment().subtract(1, 'month')}
      />
    </div>
  );
};

DateRangeBieber.defaultProps = {
  theme: 'primary',
};

export default DateRangeBieber;
