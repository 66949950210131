/** @jsxImportSource @emotion/react */
import { DialogHTMLAttributes, useState } from 'react';
import { useQuery } from '@apollo/client';
import InfiniteScroll from 'react-infinite-scroller';
import { storeTicketsQuery } from '../../graphql/private/query';
import StoreType from '../../types/StoresType';
import { Column } from '../Stores/Stores';
import TicketType from '../../types/TicketType';
import styles from './styles';
import Input from '../../components/UI/Input/Input';
import Loading from '../../components/UI/Loading/Loading';

export interface ITicketsTable extends DialogHTMLAttributes<HTMLDivElement> {
  store: StoreType;
}

const Store: React.FunctionComponent<ITicketsTable> = ({ store }) => {
  const [search, setSearch] = useState('');
  const variables = { name: store.name, first: 20, after: null };
  const {
    loading: loadingTickets,
    error: errorTickets,
    data: dataTickets,
    fetchMore,
  } = useQuery(storeTicketsQuery, {
    variables: { name: store.name, first: 20, after: null, filter: { search } },
  });

  const fetchMoreData = () => {
    //return;
    if (!loadingTickets) {
      fetchMore({
        variables: { ...variables, after: dataTickets?.storeTickets.pageInfo.endCursor },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return Object.assign({}, prev, {
            storeTickets: {
              ...prev.storeTickets,
              edges: [...prev.storeTickets.edges, ...fetchMoreResult.storeTickets.edges],
              pageInfo: fetchMoreResult.storeTickets.pageInfo,
            },
          });
        },
      });
    }
  };

  if (errorTickets) return <p>Error :(</p>;

  const columns: Column[] = [
    {
      title: 'Transact Number',
      dataIndex: 'transactNumber',
      key: 'transactNumber',
    },
    {
      title: 'Total',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
    },
    {
      title: 'Time End Date',
      dataIndex: 'timeEndDate',
      key: 'timeEndDate',
      render: (ticket: TicketType) => {
        const date = new Date(ticket.timeEndDate);
        const time = date.toLocaleTimeString();
        return (
          <div css={styles.cell}>
            {date.toLocaleDateString()} {time}
          </div>
        );
      },
    },
    {
      title: 'Payment Method',
      dataIndex: 'paymentType',
      key: 'paymentType',
    },
  ];
  return (
    <div>
      <h2>Tickets</h2>
      <div>
        <Input
          label="Search"
          value={search}
          onChange={(value: any) => {
            setSearch(value);
          }}
        />
      </div>
      {loadingTickets ? (
        <Loading />
      ) : (
        <div css={styles.table}>
          <div css={[styles.row]}>
            {columns.map((column) => (
              <div key={column.key} css={[styles.cell, styles.header]}>
                {column.title}
              </div>
            ))}
          </div>
          <div
            style={{
              height: 'calc(100vh - 200px)',
              overflow: 'auto',
            }}
          >
            <InfiniteScroll
              loadMore={() => {
                fetchMoreData();
              }}
              hasMore={dataTickets.storeTickets.pageInfo.hasNextPage}
              loader={
                <div className="loader" key={0}>
                  Loading ...
                </div>
              }
              useWindow={false}
            >
              {dataTickets.storeTickets.edges.map(({ node }: { node: any }) => {
                const ticket: TicketType = node;
                return (
                  <div key={ticket.id} css={styles.row}>
                    {columns.map((column) => {
                      const key = column.key;
                      if (column.render) {
                        return (
                          <div key={key} css={styles.cell}>
                            {column.render(ticket)}
                          </div>
                        );
                      }
                      return (
                        <div key={key} css={styles.cell}>
                          {ticket[key]}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        </div>
      )}
    </div>
  );
};

export default Store;
