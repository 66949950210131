import React, { useState, useEffect } from 'react';
import Header from '../../components/UI/Header/Header';
import './styles.css';
import Loading from '../../components/UI/Loading/Loading';
import useMixPanel from '../../utils/hooks/useMixPanel';
export interface IConsultasPageProps {}

const EVENTS = {
  CONSULTAS_PAGE_LOADED: 'consultasLoaded',
  CONSUKTAR_CLICKED: 'consultarClicked',
};
const ConsultasPage: React.FunctionComponent<IConsultasPageProps> = (props) => {
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);

  const { trackEvent } = useMixPanel();

  useEffect(() => {
    trackEvent('Consultas Page Loaded');
  }, []);

  const handleLoad = () => {
    setIsIframeLoaded(true);
  };

  return (
    <div
      style={{
        height: '100vh',
      }}
    >
      <Header />

      {!isIframeLoaded && <Loading />}
      <div
        className="invoice-container"
        style={{
          height: '100%',
          marginTop: '-52px',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <iframe
          onLoad={handleLoad}
          title="SAPyMes"
          style={{ width: '100%', height: '100%' }}
          src="https://facturaciondequ.azurewebsites.net/Consultas.aspx"
        />
      </div>
    </div>
  );
};

export default ConsultasPage;
