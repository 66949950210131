import { css } from '@emotion/react';
import { title } from 'process';

const styles = {
  navButton: css`
    display: block;
    padding: 8px;
    font-size: 16px;
    border-radius: 8px;
    background-color: transparent;
    color: #fff;
    border: none;
    cursor: pointer;
    outline: none;
    transition: all 0.2s ease-in-out;
    margin: auto;
    color: #6d7577;
    &:hover {
      background-color: #3d4dff;
      color: #fff;
    }
  `,
  navButtonContainer: css`
    display: flex;
    gap: 8px;
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  `,
  activeNavButton: css`
    background-color: #3d4dff;
    color: #fff;
  `,

  title: css`
    margin: auto;
    text-align: center;
    @media (max-width: 768px) {
    }
  `,
};

export default styles;
