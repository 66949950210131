import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

const isMixPanelEnabled = process.env.REACT_APP_MIXPANEL_TOKEN !== undefined;
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || '', {
  debug: false,
  persistence: 'localStorage',
});
const useMixPanel = () => {
  const trackEvent = (eventName: string, properties?: Record<string, any>) => {
    if (isMixPanelEnabled) {
      mixpanel.track(eventName, properties);
    }
  };

  const identify = (id: string) => {
    if (isMixPanelEnabled) {
      mixpanel.identify(id);
    }
  };

  const peopleSet = (properties: Record<string, any>) => {
    if (isMixPanelEnabled) {
      mixpanel.people.set(properties);
    }
  };

  return { trackEvent, identify, peopleSet };
};

export default useMixPanel;
