/** @jsxImportSource @emotion/react */
import { DialogHTMLAttributes, JSXElementConstructor, useEffect, useState } from 'react';
import { IRoutesProps } from '.';
import Stores from '../pages/Stores/Stores';

const StoresRoute: React.FunctionComponent<IRoutesProps> = (props) => {
  return <Stores />;
};

export default StoresRoute;
