import { css } from '@emotion/react';

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-top: 24px;
    max-width: 400px;
    margin: 0 auto;
  `,
  input: css`
    margin-bottom: 10px;
    width: 100%;
    input {
      width: 100%;

      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
    span {
      color: red;
      font-size: 12px;
    }
  `,
  button: css`
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #0056b3;
    }
  `,
  formValidationContainer: css`
    display: flex;
    align-items: center;
    height: 56px;
    width: 364px;
    height: 24px;
    padding-bottom: 16px;
    svg {
      margin-right: 8px;
    }
  `,
};

export default styles;
