/** @jsxImportSource @emotion/react */
import { DialogHTMLAttributes, JSXElementConstructor, useEffect, useState } from 'react';

import styles from './styles';

export interface IToggleProps extends DialogHTMLAttributes<HTMLDivElement> {
  active: boolean;
  id: string;
  disabled: boolean;
  label?: string;
  onChange: () => void;
}
const ToggleButton = ({ active, onChange, id, disabled, label }: IToggleProps) => {
  return (
    <div css={styles.toggleButton}>
      <label>
        <input type="checkbox" checked={active} onClick={onChange} id={id} disabled={disabled} />
        <span />
      </label>
      <span>{label}</span>
    </div>
  );
};

export default ToggleButton;
