/** @jsxImportSource @emotion/react */
import React, { DialogHTMLAttributes, Fragment, useEffect, useState } from 'react';
import { storesQuery, ticketsByDateAndStoreQuery } from '../../graphql/private/query';
import { useQuery, useLazyQuery } from '@apollo/client';
import Select from 'react-select';
import Loading from '../../components/UI/Loading/Loading';
import './styles.css';
import styles from './styles';
import Header from '../../components/UI/Header/Header';
import DateRangeBieber from '../../components/UI/DateRangeBieber';
import { CSVLink } from 'react-csv';
export interface IAdminConsultasNotasPageProps extends DialogHTMLAttributes<HTMLDivElement> {}

interface Store {
  id: string;
  name: string;
}

const Columns = [
  {
    title: 'Fecha venta',
    dataIndex: 'paymentDate',
    key: 'paymentDate',
    render: (date: string) => {
      return new Date(date).toLocaleDateString();
    },
  },
  {
    title: 'Tienda',
    dataIndex: 'store.abbreviation',
    key: 'store',
  },
  {
    title: 'No. ticket',
    dataIndex: 'transactNumber',
    key: 'transactNumber',
  },
  {
    title: 'Subtotal',
    dataIndex: 'netAmount',
    key: 'netAmount',
    render: (subtotal: string) => {
      return parseFloat(subtotal).toFixed(2);
    },
  },
  {
    title: 'IVA',
    dataIndex: 'taxAmount',
    key: 'taxAmount',
    render: (iva: string) => {
      return parseFloat(iva).toFixed(2);
    },
  },
  {
    title: 'Total',
    dataIndex: 'totalAmount',
    key: 'totalAmount',
    render: (total: string) => {
      return parseFloat(total).toFixed(2);
    },
  },
];
function getNestedValue<T>(obj: T, path: any): any {
  return path
    .split('.')
    .reduce((acc: { [x: string]: any }, part: string | number) => acc && acc[part], obj);
}

const AdminConsultasNotasPage: React.FunctionComponent<IAdminConsultasNotasPageProps> = (props) => {
  const [fechaInicio, setFechaInicio] = useState<Date | null>(null);
  const [fechaFin, setFechaFin] = useState<Date | null>(null);
  const [selectedSucursal, setSelectedSucursal] = useState<any | null>(null);
  const [sucursales, setSucursales] = useState<Store[]>([]);

  const { data, loading, error } = useQuery(storesQuery);

  const [getTickets, { data: ticketsData, loading: ticketsLoading, error: ticketsError }] =
    useLazyQuery(ticketsByDateAndStoreQuery);
  useEffect(() => {
    if (data) {
      setSucursales(data.stores);
    }
  }, [data]);

  const handleSelectStore = (s: any) => {
    setSelectedSucursal(s);
  };

  const handleSearch = () => {
    const variables = {
      storeIds: selectedSucursal?.map((s: any) => s.value),
      startDate: fechaInicio?.toISOString(),
      endDate: fechaFin?.toISOString(),
      first: 20,
      after: null,
      facturas: false,
    };

    getTickets({ variables });
  };

  const getCsvHeaders = () => {
    return Columns.map((column) => column.title);
  };

  const getCsvData = () => {
    return ticketsData?.ticketsByDateAndStore.map((edge: any) => {
      const ticket = edge;
      return Columns.map((column) => {
        if (column?.render) {
          return column.render(getNestedValue(ticket, column.dataIndex));
        }
        return getNestedValue(ticket, column.dataIndex);
      });
    });
  };

  const getFileName = () => {
    let filename = 'notas_';
    if (sucursales.length == 0) {
      filename += 'todas_';
    } else {
      filename += selectedSucursal.map((s: any) => s.label).join('_');
    }
    filename = `${fechaInicio?.toISOString()}_${fechaFin?.toISOString()}.csv`;
    return filename;
  };
  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <Header />
      <div
        style={{
          padding: '20px',
        }}
      >
        <h1>Consulta Notas</h1>
        <div>
          <Select
            options={sucursales.map((sucursal) => ({
              value: sucursal.id,
              label: sucursal.name,
            }))}
            onChange={handleSelectStore}
            isMulti
          />
        </div>
        <div>Selecciona las fechas</div>
        <DateRangeBieber
          startDate={fechaInicio}
          endDate={fechaFin}
          onDatesChange={({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
            setFechaInicio(startDate);
            setFechaFin(endDate);
          }}
          disabled={false}
          uniqueName={'dateRange'}
        />
        <button type="button" css={styles.ctaButton} onClick={handleSearch}>
          Buscar
        </button>

        {ticketsLoading && <Loading />}

        {ticketsData && (
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <h2>Total: {ticketsData?.ticketsByDateAndStore.length}</h2>
              </div>
              <button css={styles.ctaButton}>
                <CSVLink headers={getCsvHeaders()} data={getCsvData()} filename={getFileName()}>
                  Exportar a excel
                </CSVLink>
              </button>
            </div>
            <div css={styles.table}>
              <div css={[styles.row, styles.rowSix]}>
                {Columns.map((column) => (
                  <div css={styles.cell} key={column.key}>
                    {column.title}
                  </div>
                ))}
              </div>
              <div
                style={{
                  height: 'calc(100vh - 330px)',
                  overflow: 'auto',
                }}
              >
                {ticketsData?.ticketsByDateAndStore.map((edge: any) => {
                  const ticket = edge;
                  return (
                    <div key={ticket.id} css={[styles.row, styles.rowSix]}>
                      {Columns.map((column) => {
                        if (column?.render)
                          return (
                            <div css={styles.cell} key={column.key}>
                              {column.render(getNestedValue(ticket, column.dataIndex))}
                            </div>
                          );
                        return (
                          <div css={styles.cell} key={column.key}>
                            {getNestedValue(ticket, column.dataIndex)}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminConsultasNotasPage;
