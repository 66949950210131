import { css } from '@emotion/react';
import { colors } from '../themes/color';
const styles = {
  toggleButton: css`
    display: flex;
    column-gap: 10px;
    align-items: center;
    width: fit-content;
    label {
      position: relative;
      display: inline-block;
      width: 24px;
      height: 14px;
      margin: auto;
      input {
        opacity: 0;
        width: 0;
        height: 0;
      }
      span {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        background-color: ${colors.gray};
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 34px;
      }
      span:before {
        position: absolute;
        content: '';
        height: 10px;
        width: 10px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        transition: 0.4s;
        border-radius: 25px;
      }
      input: checked + span {
        background-color: ${colors.blurple};
      }
      input: focus + span {
        box-shadow: 0 0 1px #2196f3;
      }
      input:checked + span:before {
        transform: translateX(10px);
      }
    }
  `,
};

export default styles;
