const AUTH_TOKEN = 'authToken';
const CURRENT_USER_ID = 'currentUserId';

export const getAuthToken = () => {
  try {
    return JSON.parse(localStorage.getItem(AUTH_TOKEN) || '');
  } catch (e) {
    return null;
  }
};

const setAuthToken = (token: string) => {
  localStorage.setItem(AUTH_TOKEN, JSON.stringify(token));
};

const setCurrentUserId = (currentUserId: string) => {
  localStorage.setItem(CURRENT_USER_ID, JSON.stringify(currentUserId));
};

const removeAuthToken = () => {
  localStorage.removeItem(AUTH_TOKEN);
};

const removeCurrentUserId = () => {
  localStorage.removeItem(CURRENT_USER_ID);
};

export const setUser = async (token: string, currentUser: any) => {
  setAuthToken(token);
  setCurrentUserId(currentUser?.id);
};

export const logoutUser = async () => {
  removeAuthToken();
  removeCurrentUserId();
};

export const isLoggedIn = () => {
  const token = getAuthToken();
  return !!token;
};
