/** @jsxImportSource @emotion/react */
import { DialogHTMLAttributes, JSXElementConstructor, useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { storesQuery } from '../../graphql/private/query';
import storeUpdateMutation from '../../graphql/private/mutation/storeUpdate';
import StoreRow from './StoreRow';
import { Store } from '../../types';

import styles from './styles';
import ToggleButton from '../../components/UI/ToggleButton/ToggleButton';
import Loading from '../../components/UI/Loading/Loading';
import useMixPanel from '../../utils/hooks/useMixPanel';
import Header from '../../components/UI/Header/Header';

export interface IStoresProps extends DialogHTMLAttributes<HTMLDivElement> {}

export interface Column {
  title: string;
  dataIndex: string;
  key: string;
  render?: (store: any) => JSX.Element;
}

const Stores: React.FunctionComponent<IStoresProps> = (props) => {
  const { loading, error, data } = useQuery(storesQuery);

  const [updateStore] = useMutation(storeUpdateMutation);
  const { trackEvent } = useMixPanel();
  useEffect(() => {
    trackEvent('Stores Page Loaded');
  }, []);
  const handleToggle = (id: string, value: boolean) => {
    updateStore({
      variables: {
        id,
        syncingWithCfdi: value,
      },
    });
  };

  if (loading) return <Loading />;
  if (error) return <p>Error :(</p>;

  const columns: Column[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (store: Store) => {
        const name = store.name.substring(0, 2) === 'DQ' ? store.name.substring(2) : store.name;
        return (
          <Link to={`/stores/${store.name}`} css={styles.cell}>
            {name}
          </Link>
        );
      },
    },
    {
      title: 'Code',
      dataIndex: 'abbreviation',
      key: 'abbreviation',
    },
    {
      title: 'Last Sync',
      dataIndex: 'lastSync',
      key: 'lastSync',
      render: (stores: Store) => {
        const date = new Date(stores.lastSync);
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        const formattedTime = `${hours}:${minutes}:${seconds}`;
        return (
          <div css={styles.cell}>
            <div style={{ marginBottom: '4px' }}>{date.toLocaleDateString()}</div>
            <div>{`${formattedTime}`}</div>
          </div>
        );
      },
    },
    {
      title: 'Version',
      dataIndex: 'pluginVersion',
      key: 'pluginVersion',
    },
    {
      title: 'Sleep Time',
      dataIndex: 'sleepTimeSeconds',
      key: 'sleepTimeSeconds',
      render: (store: Store) => {
        const min = Math.floor(store.sleepTimeSeconds / 60);
        const sec = store.sleepTimeSeconds % 60;
        return <div css={styles.cell}>{`${min} min ${sec} sec`}</div>;
      },
    },
    {
      title: 'Syncing',
      dataIndex: 'syncingWithCfdi',
      key: 'syncingWithCfdi',
      // render: (store: Store) => <div css={styles.cell}>{store.syncingWithCfdi ? 'Yes' : 'No'}</div>,
      render: (store: Store) => (
        <ToggleButton
          active={store.syncingWithCfdi}
          onChange={() => {
            handleToggle(store.id, !store.syncingWithCfdi);
          }}
          id={store.id}
          disabled={false}
          // label={store.syncingWithCfdi ? 'Yes' : 'No'}
        />
      ),
    },
    {
      title: 'Time since last sync',
      dataIndex: 'lastSyncNow',
      key: 'lastSyncNow',
      render: (store: Store) => {
        const now = new Date();
        const sDate = new Date(store.lastSync);
        const diff = now.getTime() - sDate.getTime();
        const hours = Math.floor(diff / 1000 / 60 / 60);
        const diffInMinutes = Math.floor(diff / 1000 / 60);
        if (hours > 0) return <div css={styles.cell}>{`${hours} h ${diffInMinutes % 60} min`}</div>;
        return <div css={styles.cell}>{`${diffInMinutes} min`}</div>;
      },
    },
    {
      title: 'Ticket last sync',
      dataIndex: 'lastTicketSync',
      key: 'lastTicketSync',
      render: (store: Store) => {
        if (!store.lastTicket?.timeEndDate) return <div> - </div>;
        const now = new Date();
        const sDate = new Date(store.lastTicket.timeEndDate);
        const diff = now.getTime() - sDate.getTime();
        const hours = Math.floor(diff / 1000 / 60 / 60);
        const diffInMinutes = Math.floor(diff / 1000 / 60);
        if (hours > 0) return <div css={styles.cell}>{`${hours} h ${diffInMinutes % 60} min`}</div>;
        return <div css={styles.cell}>{`${diffInMinutes} min`}</div>;
      },
    },
  ];

  return (
    <div>
      <Header />
      <div
        style={{
          padding: '20px',
        }}
      >
        <h1>Stores</h1>
        <div css={styles.storeTable}>
          <div css={styles.storeRow}>
            {columns.map((column) => (
              <div key={column.key} css={[styles.cell, styles.columnHeader]}>
                {column.title}
              </div>
            ))}
          </div>
          {data.stores.map((store: Store) => (
            <StoreRow key={store.id} store={store} columns={columns} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Stores;
