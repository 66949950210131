import { gql } from '@apollo/client';
import ticketFragment from '../../fragments/ticketFragment';

const ticketsByDateAndStoreQuery = gql`
  query ticketsByDateAndStoreQuery(
    $storeIds: [ID!]
    $startDate: ISO8601DateTime!
    $endDate: ISO8601DateTime!
    $facturas: Boolean!
  ) {
    ticketsByDateAndStore(
      storeIds: $storeIds
      startDate: $startDate
      endDate: $endDate
      facturas: $facturas
    ) {
      ...TicketFragment
    }
  }
  ${ticketFragment}
`;

export default ticketsByDateAndStoreQuery;
