/** @jsxImportSource @emotion/react */
import { DialogHTMLAttributes, JSXElementConstructor, useEffect, useState } from 'react';
import { IRoutesProps } from '.';
import Store from '../pages/Store/Store';

const StoreRoute: React.FunctionComponent<IRoutesProps> = (props) => {
  return <Store />;
};

export default StoreRoute;
