import { gql } from '@apollo/client';
import FilterType from '../../types/FilterType';
import ticketFragment from '../../fragments/ticketFragment';

const storeQuery = gql`
  query storeTicketsQuery(
    $name: String
    $id: ID
    $first: Int
    $after: String
    $filter: TicketFilterInput
  ) {
    storeTickets(name: $name, id: $id, first: $first, after: $after, filter: $filter) {
      edges {
        cursor
        node {
          ...TicketFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${ticketFragment}
`;

export default storeQuery;
