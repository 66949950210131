import { gql } from '@apollo/client';
import userFragment from '../../fragments/userFragment';
const currentUserQuery = gql`
  query CurrentUserQuery {
    currentUser {
      ...UserFragment
    }
  }
  ${userFragment}
`;

export default currentUserQuery;
