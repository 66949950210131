import React from 'react';

export interface InputProps {
  type: string;
  name: string;
  label: string;
  defaultValue: any;
  errors: any;
  register: any;
  rules?: any;
}

const RHFInput: React.FC<InputProps> = ({
  type,
  label,
  defaultValue,
  errors,
  register,
  name,
  rules,
}) => {
  return (
    <label style={{ display: 'flex', marginBottom: '10px', flexDirection: 'column' }}>
      {label}
      <input
        type={type}
        defaultValue={defaultValue}
        {...register(name, rules)}
        style={{
          display: 'block',
          padding: '8px',
          fontSize: '16px',
          borderRadius: '4px',
          border: '1px solid #ddd',
          maxWidth: '300px',
        }}
      />
      {errors[name] && <span style={{ color: 'red' }}>This field is required</span>}
    </label>
  );
};

export default RHFInput;
