import React, { useEffect, useRef } from 'react';
import { useMutation } from '@apollo/client';
import Header from '../../components/UI/Header/Header';
import './styles.css';
import Loading from '../../components/UI/Loading/Loading';
import useMixPanel from '../../utils/hooks/useMixPanel';
import { createInvoiceMutation } from '../../graphql/public/mutation';
import dqLogo from '../../assets/icons/dqLogo.svg';
export interface IFacturasPageProps {}

const EVENTS = {
  FACTURAS_PAGE_LOADED: 'facturasLoaded',
  AGREGAR_CLICKED: 'agregarClicked',
  AGREGAR_CLICKED_DATA: 'agregarClickedData',
  AGREGAR_SUCCESS: 'agregarSuccess',
  FACTURAR_CLICKED: 'FacturarClicked',
  FACTURAR_SUCCESS: 'FacturarSuccess',
};

const FacturasPage: React.FunctionComponent<IFacturasPageProps> = (props) => {
  const [isIframeLoaded, setIsIframeLoaded] = React.useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [agregarFactura] = useMutation(createInvoiceMutation);

  const { trackEvent, peopleSet, identify } = useMixPanel();

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      // Check if the message is from the iframe
      if (event.source !== iframeRef.current?.contentWindow) {
        return;
      }

      // Check the message content
      const data = event.data;
      if (data.type === 'facturasLoaded') {
        // Do something in your React app
        trackEvent('Iframe facturas loaded');
      } else if (data.type === 'agregarClicked') {
        // Do something in your React app
        trackEvent('Agregar was clicked');
      } else if (data.type === 'agregarClickedData') {
        // Do something in your React app
        const { sucursal, transact_number: transactNumber, monto } = data;
        trackEvent('Agregar was clciked with data', {
          sucursal,
          transactNumber,
          monto,
        });
      } else if (data.type === 'agregarSuccess') {
        // Do something in your React app
        trackEvent('Agregar success');
      } else if (data.type === 'FacturarClick') {
        // Do something in your React app
        const { correo, razon, rfc, sucursal, transact_number: transactNumber } = data;
        try {
          identify(correo);
          peopleSet({
            $email: correo,
            name: razon,
          });
          trackEvent('Facturar was clicked', {
            correo,
            razon,
            rfc,
            sucursal,
            transactNumber,
          });
        } catch (error) {
          console.error('Error tracking event', error);
        }
      } else if (data.type === 'FacturarSuccess') {
        // Do something in your React app
        // TODO: Add folio to the event
        const { correo, razon, rfc, sucursal, transact_number: transactNumber, folio } = data;
        try {
          trackEvent('Facturar success', {
            correo,
            razon,
            rfc,
            sucursal,
            transactNumber,
            folio,
          });
        } catch (error) {
          console.error('Error tracking event', error);
        }
        try {
          agregarFactura({
            variables: {
              invoiceNumber: folio,
              transactNumber,
              storeName: `DQ ${sucursal}`,
              rfc,
              email: correo,
              clientName: razon,
            },
          });
        } catch (error) {
          console.error('Error creating invoice', error);
          trackEvent('Error creating invoice', {
            message: error,
          });
        }
      } else if (data.type === 'ErrorFound') {
        const { error } = data;
        console.error('Error found', error);
        trackEvent('Error found', {
          message: error,
        });
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    trackEvent('Facturas Page Loaded');
  }, []);

  const handleLoad = () => {
    setIsIframeLoaded(true);
  };

  return (
    <div
      style={{
        height: '100vh',
      }}
    >
      <Header />
      {!isIframeLoaded && <Loading />}
      <div
        className="invoice-container"
        style={{
          height: 'calc(100vh - 26px)',
          marginTop: '-52px',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <iframe
          className="iframe"
          onLoad={handleLoad}
          title="SAPyMes"
          style={{ width: '100%', height: '100%' }}
          src={`${process.env.REACT_APP_SAPYMES_URL}/Factura.aspx`}
          ref={iframeRef}
        />
      </div>
      {isIframeLoaded && (
        <div className="contact-container-2">
          <div className="contact-container-3">
            <img src={dqLogo} alt="DQ Logo" style={{ height: '100px', margin: 'auto' }} />
            <div
              style={{
                fontFamily: 'inherit',
              }}
            >
              <h2>Contacto:</h2>
              <div
                style={{
                  fontSize: '16px',
                  lineHeight: '1.5',
                }}
              >
                Si tienes alguna duda o problema, por favor mandanos un correo con la informacion de
                tu ticket y tus datos fiscales y con gusto te atenderemos.
              </div>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'auto 1fr',
                  gap: '8px',
                  marginTop: '8px',
                  fontFamily: 'inherit',
                  fontSize: '16px',
                  lineHeight: '1.5',
                }}
              >
                <div>Correo:</div>
                <a
                  href="mailto:facturacion@dequ.mx"
                  style={{
                    color: 'white',
                    textDecoration: 'underline',
                  }}
                  onClick={() => {
                    trackEvent('Contact email clicked');
                  }}
                >
                  facturacion@dequ.mx
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FacturasPage;
