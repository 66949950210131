import gql from 'graphql-tag';

const storeUpdateMutation = gql`
  mutation StoreUpdateMutation(
    $id: ID!
    $syncingWithCfdi: Boolean
    $sleepTimeSeconds: Int
    $abbreviation: String
  ) {
    storeUpdate(
      input: {
        id: $id
        syncingWithCfdi: $syncingWithCfdi
        sleepTimeSeconds: $sleepTimeSeconds
        abbreviation: $abbreviation
      }
    ) {
      store {
        id
        name
        syncingWithCfdi
        sleepTimeSeconds
        abbreviation
      }
    }
  }
`;

export default storeUpdateMutation;
