import gql from 'graphql-tag';

const createInvoiceMutation = gql`
  mutation CreateInvoiceMutation(
    $transactNumber: String!
    $storeName: String!
    $invoiceNumber: String!
    $rfc: String!
    $email: String!
    $clientName: String!
  ) {
    createInvoice(
      input: {
        transactNumber: $transactNumber
        storeName: $storeName
        invoiceNumber: $invoiceNumber
        rfc: $rfc
        email: $email
        clientName: $clientName
      }
    ) {
      ticket {
        id
      }
    }
  }
`;

export default createInvoiceMutation;
