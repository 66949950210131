import { css } from '@emotion/react';

const styles = {
  table: css`
    display: grid;
    border: 1px solid #ccc;
    margin: 20px 0;
    overflow: auto;
    border-radius: 8px;
  `,
  row: css`
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    column-gap: 1rem;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
  `,
  rowSix: css`
    grid-template-columns: repeat(6, 1fr);
  `,
  cell: css`
    flex: 1;
    padding: 5px;
    text-align: center;
    width: 100px;
    margin: auto;
    overflow: auto;
  `,
  header: css`
    font-weight: bold;
  `,
  ctaButton: css`
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 5px;
    margin: 10px 0;
    cursor: pointer;
    &:hover {
      background-color: #e0e0e0;
    }
  `,
};

export default styles;
